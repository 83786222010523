.fda-product-code-builder-button {
  margin-top: var(--space-4);
}
.no-classification {
  font-weight: var(--font-weight-3);
  font-size: var(--font-size-16);
  color: black;
}
.product .product-poNumbers {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product .ant-radio-button-wrapper {
  width: 100px;
  text-align: center;
}
.product .read-only-data-table {
  margin-bottom: 0;
}
.product .column-wrapper {
  display: flex;
}
.product .column-wrapper .left-column {
  width: 100%;
  margin-right: var(--space-4);
}
.product .column-wrapper .right-column {
  width: 312px;
  min-width: 312px;
}
.product .column-wrapper .right-column .new-doc,
.product .column-wrapper .right-column .new-doc-form-upload {
  margin-right: 0;
}
